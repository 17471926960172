import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import io from "socket.io-client";
import './chat.css'
// dayjs
import dayjs from 'dayjs'
// material-ui
// import { useTheme } from '@mui/material/styles'
import Snackbar from '@mui/material/Snackbar';
import {
  Box,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  TextField,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
// third-party
// import PerfectScrollbar from 'react-perfect-scrollbar'
import Picker, { SkinTones } from 'emoji-picker-react'

// project imports
import ChartHistory from './ChartHistory'
import MainCard from '../cards/MainCard'
// import UserAvatar from './components/chat/UserAvatar'

// assets
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone'
import MoodTwoToneIcon from '@mui/icons-material/MoodTwoTone'
import { KAFKA_GROUP, gridSpacing } from '../../const';
import { getListChatHistoryRequest, pushListChatHistoryRequest } from '../../lib/redux/actions/chatAction'

const socket = io(process.env.REACT_APP_SOCKET_CHAT_URL);

function Chat() {
  console.log('Render Chat')
  const dispatchAction = useDispatch()
  // const theme = useTheme()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('user_id'); // Retrieve the value of a specific query parameter
  const chat = useSelector((state) => state.chat)
  const loading = chat?.messages?.loading
  const [message, setMessage] = useState("");
  const messages = chat?.data;

  const [state, setState] = React.useState({
    msg: null,
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open, msg } = state;
  const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (userId) {
      // Join socket
      socket.emit("join", { userId, group: KAFKA_GROUP });

      const messageHandler = (data) => {
        if (data.receiver === userId) {
          dispatchAction(pushListChatHistoryRequest(data, () => { }, () => { }))
        }
        // setMessages((prev) => {
        //   // Kiểm tra nếu tin nhắn đã tồn tại
        //   if (!prev.some((msg) => msg.id === data.id)) {
        //     return [...prev, data];
        //   }
        //   return prev;
        // });
      };

      socket.on("receive_message", messageHandler);

      return () => {
        socket.off("receive_message", messageHandler);
      };
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      // Get list messages
      const paramApi = { group: KAFKA_GROUP, userId }

      const handleGetListChatHistorySuccess = () => { }

      const handleGetListChatHistoryFailed = () => { }

      const getListChatHistoryData = () => {
        dispatchAction(getListChatHistoryRequest(paramApi, handleGetListChatHistorySuccess, handleGetListChatHistoryFailed))
      }

      getListChatHistoryData()
    }
  }, []);

  const sendMessage = () => {
    // Check sender not null
    if (!userId) {
      setState({ ...state, open: true, msg: 'Tin nhắn gửi phải có người gửi' });
      return
    }
    if (message === '') {
      setState({ ...state, open: true, msg: 'Vui lòng nhập tin nhắn' });
      return
    }
    socket.emit("send_message", { sender: userId, group: "AdminSupport", message });
    // setMessages((prev) => [...prev, { sender: userId, message }]);
    dispatchAction(pushListChatHistoryRequest({
      sender: userId,
      receiver: 'AdminSupport',
      isFromAdmin: false,
      message,
      timeStamp: dayjs()
    }, () => { }, () => { }))
    setMessage("");
  };

  const handleEnter = (event) => {
    if (event?.key !== 'Enter' || event?.shiftKey) {
      return
    }
    sendMessage()
  }

  // handle emoji
  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
  }

  const [anchorElEmoji, setAnchorElEmoji] = useState() /** No single type can cater for all elements */
  const handleOnEmojiButtonClick = (event) => {
    setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget)
  }

  const emojiOpen = Boolean(anchorElEmoji)
  const emojiId = emojiOpen ? 'simple-popper' : undefined
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }} style={{ padding: `0px 5px 0px 5px` }}>
        <Grid container spacing={gridSpacing} style={{ padding: `0px` }}>
          <Grid item xs zeroMinWidth style={{ padding: `0px` }}>
            <MainCard sx={{ border: 'none', boxShadow: `none`, padding: `0px` }}>
              <Grid container spacing={gridSpacing} style={{ display: `inline-block`, padding: 0, marginTop: `5px`, marginBottom: `5px` }}>
                {loading ? <CardContent style={{ width: `100%` }}><Box sx={{ display: 'flex', justifyContent: 'center', alignItems: `center`, height: `${viewportHeight - 100}px` }}>
                  <CircularProgress />
                </Box ></CardContent> : <>
                  {/* <PerfectScrollbar style={{ height: 'calc(100vh - 80px)', overflowY: `auto` }}}> */}
                  <CardContent style={{ height: `${viewportHeight - 100}px`, overflowY: `auto`, padding: 0 }}>
                    <ChartHistory
                      data={messages}
                    />
                  </CardContent>
                  {/* </PerfectScrollbar> */}
                  <Grid item xs={12}>
                    <div className="chat-input-container">
                      <Grid container spacing={1} alignItems="center">
                        {/* <Grid item>
                      <IconButton size="large">
                        <AttachmentTwoToneIcon />
                      </IconButton>
                    </Grid> */}
                        <Grid item>
                          <IconButton ref={anchorElEmoji} aria-describedby={emojiId} onClick={handleOnEmojiButtonClick} size="large">
                            <MoodTwoToneIcon />
                          </IconButton>
                          <Popper
                            id={emojiId}
                            open={emojiOpen}
                            anchorEl={anchorElEmoji}
                            disablePortal
                            popperOptions={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-20, 20]
                                  }
                                }
                              ]
                            }}
                          >
                            <ClickAwayListener onClickAway={handleCloseEmoji}>
                              <MainCard elevation={8} content={false}>
                                <Picker onEmojiClick={onEmojiClick} skinTone={SkinTones} disableAutoFocus />
                              </MainCard>
                            </ClickAwayListener>
                          </Popper>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                          <TextField
                            fullWidth
                            label="Nhập nội dung tin nhắn"
                            value={message}
                            onChange={(e) => { setMessage(e.target.value) }}
                            onKeyPress={handleEnter}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton color="primary" onClick={sendMessage} size="large">
                            <SendTwoToneIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </>}
              </Grid>
            </MainCard>
          </Grid>
        </Grid >
      </Box>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={msg}
        key={vertical + horizontal}
      />
    </>
  );
}

export default Chat;
