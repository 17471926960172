import { combineReducers } from 'redux'

// reducer import
import chatReducer from './chatReducer'
import chatMatchReducer from './chatMatchReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  chat: chatReducer,
  chatMatch: chatMatchReducer
})

export default reducer
