const ACTION_TYPE = {

  // Chat
  CHAT_GET_LIST_MESSAGES_REQUEST: 'CHAT_GET_LIST_MESSAGES_REQUEST',
  CHAT_GET_LIST_MESSAGES_SUCCESS: 'CHAT_GET_LIST_MESSAGES_SUCCESS',
  CHAT_GET_LIST_MESSAGES_FAILED: 'CHAT_GET_LIST_MESSAGES_FAILED',
  CHAT_PUSH_LIST_MESSAGES_REQUEST: 'CHAT_PUSH_LIST_MESSAGES_REQUEST',
  // Match Chat
  CHAT_GET_LIST_MATCH_MESSAGES_REQUEST: 'CHAT_GET_LIST_MATCH_MESSAGES_REQUEST',
  CHAT_GET_LIST_MATCH_MESSAGES_SUCCESS: 'CHAT_GET_LIST_MATCH_MESSAGES_SUCCESS',
  CHAT_GET_LIST_MATCH_MESSAGES_FAILED: 'CHAT_GET_LIST_MATCH_MESSAGES_FAILED',
  CHAT_PUSH_LIST_MATCH_MESSAGES_REQUEST: 'CHAT_PUSH_LIST_MATCH_MESSAGES_REQUEST'
}

export default ACTION_TYPE
