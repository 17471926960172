import PropTypes from 'prop-types'

import { Fragment, useCallback, useEffect, useRef } from 'react'
import dayjs from 'dayjs';

// material-ui
import { Card, CardContent, Grid, Typography, Avatar, ListItem, ListItemAvatar, ListItemText, } from '@mui/material'

// project imports
import { gridSpacing } from '../../const'

// ==============================|| CHAT MESSAGE HISTORY ||============================== //

const ChartHistory = ({ userId, data }) => {
  // scroll to bottom when new message is sent or received
  const scrollDiv = useRef(document.createElement('div'))
  const el = scrollDiv.current
  const scrollToBottom = useCallback(() => {
    el.scrollIntoView({ block: "end", behavior: "smooth" })
  }, [el])

  useEffect(() => {
    scrollToBottom()
  }, [data?.length, scrollToBottom])

  return (
    <Grid item xs={12}>
      <Grid container spacing={gridSpacing} ref={scrollDiv}>
        {data?.length ? data.map((history, index) => (
          <Fragment key={index}>
            {history?.sender == userId ? (
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <Card
                      sx={{
                        display: 'inline-block',
                        float: 'right',
                        bgcolor: '#e3f2fd',
                        boxShadow: 'none'
                      }}
                    >
                      <CardContent sx={{ p: 2, pb: '16px !important', width: 'fit-content', ml: 'auto' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="#000">
                              {history?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align="right" variant="subtitle2" color="#000">
                              {dayjs(history?.timeStamp).format('HH:mm:ss DD/MM/YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={10} sm={7}>
                    <Card
                      sx={{
                        display: 'inline-block',
                        float: 'left',
                        background: '#ede7f6',
                        boxShadow: 'none'
                      }}
                    >
                      <CardContent sx={{ p: 2, pb: '16px !important' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={24}>
                            <Typography variant="body2" color="#1565c0" fontWeight={'bold'}>
                              {history?.senderInfo?.name}
                            </Typography>
                            {/* <ListItem>
                              <ListItemAvatar><Avatar alt="" src="" /></ListItemAvatar>
                              <ListItemText>
                                <Typography variant="body2" color={'#FFF'} fontWeight={'bold'}>Admin COLATYSO</Typography>
                              </ListItemText>
                            </ListItem> */}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" color="#000">
                              {history?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align="right" variant="subtitle2" color="#000">
                              {dayjs(history?.timeStamp).format('HH:mm:ss DD/MM/YYYY')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </Grid>
            )}
          </Fragment>
        )) : null}
      </Grid>
    </Grid >
  )
}

ChartHistory.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.array
}

export default ChartHistory
