import ACTION_TYPE from '../../../const/ActionType'

export const getListChatMatchHistoryRequest = (payload, actionSuccess = () => { }, actionFailed = () => { }) => ({
  type: ACTION_TYPE.CHAT_GET_LIST_MATCH_MESSAGES_REQUEST,
  payload,
  actionSuccess,
  actionFailed
})

export const pushListChatMatchHistoryRequest = (payload, actionSuccess = () => { }, actionFailed = () => { }) => ({
  type: ACTION_TYPE.CHAT_PUSH_LIST_MATCH_MESSAGES_REQUEST,
  payload,
  actionSuccess,
  actionFailed
})
