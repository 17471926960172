import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { useLocation } from 'react-router-dom';
import "./widget.css"; // Import file CSS cho style
import { KAFKA_GROUP } from '../../const';

const socket = io(process.env.REACT_APP_SOCKET_CHAT_URL);

const ChatWidget = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('user_id'); // Retrieve the value of a specific query parameter
  const [unreadMessages, setUnreadMessages] = useState(0); // Giả lập tin nhắn chưa đọc

  useEffect(() => {
    if (userId) {
      // Join socket
      socket.emit("join", { userId, group: KAFKA_GROUP });

      // Lấy số lượng tin nhắn chưa đọc của user
      socket.emit("get_user_unread_count", userId)

      // Lắng nghe sự kiện cập nhật tin nhắn chưa đọc
      socket.on("update_user_unread_count", (data) => {
        if (data?.userId === userId) {
          setUnreadMessages(data?.unreadCount);
        }
      })

      return () => {
        socket.off("get_user_unread_count")
        socket.off("update_user_unread_count")
      };
    }
  }, [userId]);

  const handleClick = () => {
    // console.log("Click event inside iframe");

    socket.emit("user_open_chat", userId)

    // Gửi tin nhắn cho trang cha
    window.parent.postMessage(
      { type: "widgetClick", message: "Iframe clicked" },
      "*"
    );
  };

  return (
    <div className="chat-widget" onClick={handleClick}>
      <div className="chat-icon">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 508 508" xmlSpace="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle style={{ fill: `#54C0EB` }} cx="254" cy="254" r="254"></circle> <path style={{ fill: `#FFFFFF` }} d="M303.7,303.3c30.5-17.3,51-50.1,51-87.6c0-55.7-45.1-100.8-100.8-100.8S153.2,160,153.2,215.6 c0,37.6,20.6,70.3,51,87.6C141,319.3,89.7,365,66,424.8c46.5,51.1,113.5,83.2,188,83.2s141.5-32.1,188-83.2 C418.3,365,367,319.3,303.7,303.3z"></path> <path style={{ fill: `#324A5E` }} d="M401.6,182.3h-15.8C370.9,123.4,317.5,79.6,254,79.6s-116.9,43.7-131.8,102.7h-15.8 c-5.4,0-9.8,4.4-9.8,9.8V240c0,5.4,4.4,9.8,9.8,9.8h20c6.1,0,10.8-5.5,9.7-11.4c-2-10.4-2.7-21.3-1.8-32.5 c4.8-59.3,53.6-106.9,113.1-110.1c69.2-3.8,126.8,51.5,126.8,119.9c0,7.8-0.8,15.3-2.2,22.7c-1.2,6,3.6,11.5,9.6,11.5h1.8 c-4.2,13-14.9,37.2-38.3,50.2c-19.6,10.9-44.3,11.9-73.4,2.8c-1.5-6.7-8.9-14.6-16.5-18.3c-9.8-4.9-15.9-0.8-19.4,6.2 s-3,14.3,6.7,19.2c8.6,4.3,21.6,5.2,27,0.5c13.9,4.3,26.9,6.5,39,6.5c15,0,28.5-3.3,40.4-10c27.5-15.3,38.8-43.7,42.8-57.2h9.9 c5.4,0,9.8-4.4,9.8-9.8v-47.9C411.4,186.7,407,182.3,401.6,182.3z"></path> </g></svg>
        {unreadMessages > 0 && (
          <div className="unread-badge">{unreadMessages}</div>
        )}
      </div>
    </div>
  );
};

export default ChatWidget;
