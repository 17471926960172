import { all } from 'redux-saga/effects'

import { chatWatcher } from './chat'
import { chatMatchWatcher } from './chatMatch'

export default function* rootSaga() {
  yield all([
    chatWatcher(),
    chatMatchWatcher()
  ])
}
