import React from "react";
import { useLocation } from 'react-router-dom';

import Widget from "./components/widget";
import Chat from "./components/chat";
import Match from "./components/match";

function App() {
  console.log('Render App')
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  if (type === 'widget') {
    return <Widget />
  }
  if (type === 'chat') {
    return <Chat />
  }
  if (type === 'match') {
    return <Match />
  }
  return null
}

export default App;
